@font-face {
  font-family: 'Inter';
  src: url('./assets/fonts/Inter/Inter-VariableFont_slnt,wght.ttf') format('truetype-variations');
  font-weight: 1 999;
  font-style: normal;
}

body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Inter', sans-serif;
}
