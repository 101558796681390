.indigo {
  position: absolute;
  width: 140%;
  height: 412.55px;
  left: -300px;
  bottom: -260px;

  opacity: 0.5;
}

.bubbles {
  position: absolute;
  width: 352.58px;
  height: 522.75px;
  left: -112.58px;
  bottom: -585.75px;
  z-index: 0;
}
