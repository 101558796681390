.state {
  fill: #e0e0e0;
  stroke: #ffffff;
  stroke-width: 1;
}

/* default color for all states not specified below */

.state .selected {
  fill: #df2020;
}

.state .active {
  fill: #c7c7c7;
}

.dccircle {
  display: yes;
}

/* set display to yes to show the circle for the District of Columbia, or none to hide it */

.separator1 {
  stroke: #b0b0b0;
  stroke-width: 2;
}

/* color and width of lines around Alaska and Hawaii */
