.mapPdf .state {
  fill: #ffffff;
  stroke: #646464;
  stroke-width: 1;
}

/* default color for all states not specified below */

.mapPdf .state .selected {
  fill: #df2020;
}

.mapPdf .state .active {
  fill: #ffffff;
}

.mapPdf .dccircle {
  display: yes;
}

/* set display to yes to show the circle for the District of Columbia, or none to hide it */

.mapPdf .separator1 {
  stroke: #646464;
  stroke-width: 2;
}

/* color and width of lines around Alaska and Hawaii */
